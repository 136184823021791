@import url(https://spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);
@import url(https://cdn.rawgit.com/innks/NanumSquareRound/master/nanumsquareround.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/pretendard/1.3.9/static/pretendard.min.css);

body {
  margin: 0;
  font-family: 'Spoqa Han Sans Neo', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.login-input,
.login-input-pw {
  margin: auto;
  width: 320px !important;
}

.login-input .text-box-label,
.login-input-pw .text-box-label {
  color: var(--Gray-scale-Gray-05, #767f8a) !important;
  font-family: Spoqa Han Sans Neo;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 20px; /* 125% */
  letter-spacing: -0.096px;
}

.login-input-pw {
  margin-top: 32px;
}

.login-input input {
  height: 52px;
  background: #ffffff;
  border: 1px solid #e3e5e8 !important;
  border-radius: 8px !important;
}
.login-input input,
.login-input-pw input {
  color: var(--Gray-scale-Gray-09, #181a1c) !important;
  font-family: Spoqa Han Sans Neo;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px !important; /* 125% */
  letter-spacing: -0.096px;
}
.login-input input::placeholder,
.login-input-pw input::placeholder {
  color: var(--Gray-scale-Gray-02, #c8ccd0) !important;
  font-family: Spoqa Han Sans Neo;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px !important; /* 125% */
  letter-spacing: -0.096px;
}

.login-input-pw .input-group {
  height: 52px;
  background: #ffffff;
  border: 1px solid #e3e5e8 !important;
  border-radius: 8px !important;
}

.login-input-pw span,
.login-input-pw input {
  border-radius: 8px !important;
  border: none !important;
}
.btn-login:disabled {
  border-radius: 8px;
  border-color: var(--Gray-scale-Gray-01, #e3e5e8) !important;
  background: var(--Gray-scale-Gray-01, #e3e5e8) !important;
  color: var(--Default-White, #fff) !important;
  font-family: Spoqa Han Sans Neo;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 20px !important; /* 125% */
  letter-spacing: -0.096px;
}
.login-form-error {
  position: absolute;
  top: -26px;
  color: var(--System-Error, #ff4f4f);
  font-family: Spoqa Han Sans Neo;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  letter-spacing: -0.084px;
}

.layout-main-content {
  background-color: transparent !important;
  border: none !important;
}

#top-bar-menu {
  display: flex;
  justify-content: space-between;
  padding: 27px 32px;
  height: 72px;
  background: #ffffff;
  border-bottom: 1px solid #e3e5e8;
}

#top-bar-menu .page-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.006em;
  text-transform: capitalize;
  color: #464c52;
}

#top-bar-menu .logout-bt {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.006em;
  color: #5e656e;
  cursor: pointer;
}

.tb-search-text .input-group {
  width: 424px;
  height: 52px;
  background: #ffffff;
  border: 1px solid #e3e5e8;
  border-radius: 8px 0px 0px 8px;
}
.tb-search-text input {
  border: none !important;
  border-radius: 8px !important;
}
.bt-search {
  width: 74px;
  height: 52px;
  left: 630px;
  top: 136px;
  background: #ffffff;
  color: #767f8a !important;
  border-right: 1px solid #e3e5e8 !important;
  border-bottom: 1px solid #e3e5e8 !important;
  border-top: 1px solid #e3e5e8 !important;
  border-radius: 0px 8px 8px 0px !important;
}

.cJAASr .nav-link {
  padding: 0px !important;
  padding-bottom: 11px !important;
  margin-right: 48px;
}

.cUHbjp .nav-link {
  padding: 0px !important;
  padding-bottom: 11px !important;
  margin-right: 48px;
}

.cJAASr .nav-link.active {
  border: none !important;
  border-bottom: 1px solid #3593fb !important;
  padding: none !important;
}

.cUHbjp .nav-link.active {
  border: none !important;
  border-bottom: 1px solid #3593fb !important;
}

.cJAASr li:first-child {
  margin-left: 32px !important;
}

.cUHbjp li:first-child {
  margin-left: 32px !important;
}

.text-align-center {
  text-align: center;
}

.div-middle {
  margin: auto;
}

.bd-none {
  border: none !important;
}

.tb-content {
  color: unset;
  text-decoration: unset;
}

.modal-wrapper {
  justify-content: center;
}

.modal-content-wrapper {
  width: 332px !important;
}

.modal-content-wrapper__form {
  min-width: 752px !important;
  min-height: 300px;
}
.modal-content-wrapper__form
  > .modal-body
  .modal-content-form
  .modal-content-form__title
  .form__title {
  line-height: 23px;
  font-weight: 700;
  font-size: 25px;
}

.modal-content-wrapper__form
  > .modal-body
  .modal-content-form
  .modal-content-form__title {
  width: 100%;
  align-items: center;
  min-height: 50px;
  height: 100% !important;
  display: flex;
}

.modal-footer {
  border: none !important;
}

.modal-dialog-centered {
  justify-content: center;
}

.detail-card:not(:first-child):not(:last-child) {
  border-top: none;
  border-radius: 0px;
}

.detail-card:not(:first-child):not(:last-child) .detail-header {
  height: 50px;
  line-height: 40px;
  background: white;
  border-bottom: none;
  padding-left: 24px;
  padding-top: 24px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  color: var(--Gray-scale-Gray-05, #767f8a);
  font-family: Spoqa Han Sans Neo;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  letter-spacing: -0.096px;
}

.detail-card:not(:first-child):not(:last-child) .detail-body {
  padding-top: 0px;
  padding-bottom: 24px;
  padding-left: 24px;
  color: var(--Gray-scale-Gray-07, #464c52);
  font-family: Spoqa Han Sans Neo;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  letter-spacing: -0.096px;
}

.detail-card:first-child {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.detail-card:first-child .detail-header {
  height: 50px;
  line-height: 40px;
  background: white;
  border-bottom: none;
  padding-left: 24px;
  padding-top: 24px;
  padding-bottom: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  align-items: center;
  color: var(--Gray-scale-Gray-05, #767f8a);
  font-family: Spoqa Han Sans Neo;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  letter-spacing: -0.096px;
}

.detail-card:first-child .detail-body {
  padding-top: 0px;
  padding-bottom: 24px;
  padding-left: 24px;
  color: var(--Gray-scale-Gray-07, #464c52);
  font-family: Spoqa Han Sans Neo;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  letter-spacing: -0.096px;
}

.detail-card:last-child {
  border-top: none;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.detail-card:last-child .detail-header {
  height: 50px;
  line-height: 40px;
  background: white;
  border-bottom: none;
  padding-left: 24px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.detail-card:last-child .detail-body {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
  display: flex;
  justify-content: flex-end;
}

.detail-card-left {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.detail-card-left .detail-header-left {
  height: 64px;
  line-height: 64px;
  background: white;
  border-bottom: 1px solid #e3e5e8;
  padding-left: 24px;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  display: flex;
  align-items: center;
  color: var(--Gray-scale-Gray-05, #767f8a);
  font-family: Spoqa Han Sans Neo;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  letter-spacing: -0.096px;
}

.detail-card-left .detail-body-left {
  padding: 32px 24px;
  min-height: 312px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 24px 20px;
}

.detail-card-left-content {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.detail-card-left-content .detail-header-left-content {
  height: 64px;
  line-height: 64px;
  background: white;
  border-bottom: 1px solid #e3e5e8;
  padding-left: 24px;
  display: flex;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: var(--Gray-scale-Gray-05, #767f8a);
  /* Title_spoqa/Title 3 */
  font-family: Spoqa Han Sans Neo;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  letter-spacing: -0.096px;
}

.detail-card-left-content .detail-body-left-content {
  padding: 20px 24px;
  height: 380px;
}

/* .detail-action-group {
} */

.detail-action-group .delete-btn {
  width: 144px;
  height: 52px;
  background: #ffffff;
  border: 1px solid #e3e5e8;
  border-radius: 8px;
  color: #ff4f4f;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.custom-drop-file .position-relative {
  display: flex;
  flex-direction: row-reverse !important;
  justify-content: flex-end;
}

.custom-drop-file .mt-4 {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.custom-drop-file .mt-4 .col {
  flex: none;
}

.custom-drop-file .position-relative .dropzone {
  margin: 0 auto;
  height: 344px;
  width: 496px;
}

.row-post-content {
  /* margin-left: 280px !important;
  margin-top: -120px !important; */
  height: 206px;
}

.text-area-custom textarea {
  width: 496px;
  border: none !important;
  border-left: 1px solid #e3e5e8 !important;
  border-right: 1px solid #e3e5e8 !important;
  border-radius: 0px !important;
  border-color: #e3e5e8 !important;
}

.text-box-focus .text-box {
  border-color: #e3e5e8 !important;
}

.text-content-eclipse {
  white-space: pre-line;
  display: -webkit-box;
  /* max-width: 200px; */
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-align: justify;
  overflow: hidden;
  color: var(--Gray-scale-Gray-07, #464c52);
  font-family: Spoqa Han Sans Neo;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.text-content-elipsis {
  white-space: pre-line;
  display: -webkit-box;
  /* max-width: 200px; */
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.medical-bt-group {
  padding-bottom: 16px;
  padding-top: 16px;
}

.medical-bt-delete {
  background: #ffffff !important;
  border: 1px solid #e3e5e8 !important;
  border-radius: 8px !important;
  width: 144px;
  height: 52px;
  color: #ff4f4f !important;
  margin-right: 16px;
  font-family: Spoqa Han Sans Neo !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 20px !important; /* 125% */
  letter-spacing: -0.096px !important;
}
.medical-bt-cancel {
  background: #ffffff !important;
  border: 1px solid #e3e5e8 !important;
  border-radius: 8px !important;
  width: 144px;
  height: 52px;
  color: #464c52 !important;
  margin-right: 16px;
  font-family: Spoqa Han Sans Neo !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 20px !important; /* 125% */
  letter-spacing: -0.096px !important;
}
.medical-bt-completed {
  background: #3593fb !important;
  border-radius: 8px !important;
  border: 1px solid #e3e5e8 !important;
  width: 144px;
  height: 52px;
  color: #ffffff !important;
  font-family: Spoqa Han Sans Neo !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 20px !important; /* 125% */
  letter-spacing: -0.096px !important;
}
.slide-container {
  margin: 48px 84px;
  border-radius: 4px;
  border: 1px solid var(--Gray-scale-Gray-01, #e3e5e8);
  background: var(--Default-BG, #f8f9f9);
  height: 405px;
}

.slide-container .react-slideshow-container .default-nav:last-of-type {
  right: -52px;
}
.slide-container .react-slideshow-container .default-nav:first-of-type {
  left: -52px;
}

.medical-img-indi {
  position: absolute;
  right: 100px;
  margin-top: -60px;
  background: #767f8a;
  border-radius: 12px;
  padding: 4px 8px;
  color: #fff;
  display: none;
}

.medical-img-indi.active {
  display: initial !important;
}
.react-slideshow-container + ul.indicators {
  margin-top: -10px;
}

.simplebar-content-wrapper {
  overflow: auto !important;
  height: 100% !important;
}

.select-tag--custom {
  width: 100%;
}
.select-tag--custom .select-tag__wrapper {
  position: relative;
  height: 42px;
}

.select-tag--custom .select-tag__wrapper .select-tag__item-list {
}

.select-tag--custom .select-tag__wrapper .select-tag__input {
  height: 100%;
}
.select-tag__input > input {
  width: 100%;
  height: 100%;
}

.select-tag--custom .select-tag__dropdown {
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: 300px;
}

.select-tag__dropdown .select-tag__dropdown-item {
}

/* overide react-select */
.react-select--custom {
  /* background: red; */
  height: 42px;
}

.react-select--custom div[class*='-control'] {
  height: 42px;
  border: 1px solid var(--gray-scale-gray-01, #e3e5e8);
}

.react-select--custom
  div[class*='-control']
  div[class*='-ValueContainer']
  div[class*='-multiValue'] {
  display: flex;
  align-items: center;
  height: 26px;
  padding: 0 10px;
  background: #fff;
  color: var(--navy, #2f4454);
  font-family: Spoqa Han Sans Neo;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  letter-spacing: -0.072px;
  border-radius: 30px;
  border: 1px solid #c9c9c9;
  background: var(--default-white, #fff);
}

div[class*='-multiValue'] div[class*='-MultiValueRemove'] {
  display: none;
}

[class*='-indicatorSeparator'] {
  display: none;
}

[class*='-IndicatorsContainer'] [class*='-indicatorContainer']:last-child {
  display: none;
}

.form-item__lable--required {
  position: relative;
}
.form-item__lable--required::after {
  position: absolute;
  content: '*';
  color: red;
  top: -4px;
  font-size: 18px;
  right: -10px;
}
.btn--edit {
  padding: 2px;
  text-align: center;
  border-radius: 17px;
  border: 1px solid var(--blue_2, #3d95fc);
  color: var(--blue_2, #3d95fc);
  cursor: pointer;
  background: #fff;
}

/* diseases tag area */
.diseases-tag .diseases-tag__inner {
  display: flex;
  width: 100%;
  column-gap: 24px;
  max-height: 730px;
  align-items: flex-start;

  ::-webkit-scrollbar {
    width: 10px;
    background: var(--dark-gray-dark-gray-100, #f5f5f5);
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 8px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: silver;
    border-radius: 8px;
  }

  /* Handle on hover */
  /* ::-webkit-scrollbar-thumb:hover {
  } */
}
.diseases-tag .form-item-diseases__event {
  height: 100%;
}

.diseases-tag__footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-diseases-tag__create--submit {
  display: flex;
  width: 380px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #3593fb;
  height: 56px;
  color: var(--default-white, #fff);
  text-align: center;
  margin-top: 50px;
  /* Subtitle/Subtitle05 */
  font-family: 'Pretendard';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  cursor: pointer;
}
.diseases-tag .input-group {
  height: 100% !important;
}
.diseases-tag .form-item-diseases__event textarea {
  /* height: calc(100% - 54px) !important; */
  border: none !important;
  height: 665px !important;
}

.diseases-tag .diseases-tag-left {
  border-radius: 8px;
  border: 1px solid #e3e5e8;
  width: 280px;
  background: #fff;
  max-height: 100%;
  overflow-y: auto;
}
.diseases-tag .diseases-tag-right {
  flex: 1;
  border-radius: 8px;
  border: 1px solid #e3e5e8;
  background: #fff;
}

.diseases-tag-right__title {
  width: 100%;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--dark-gray-dark-gray-100, #f5f5f5);
  border-radius: 8px 8px 0 0;
  color: var(--navy, #2f4454);
  font-family: 'Pretendard';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}

.diseases-tag__category-item {
  border-bottom: 1px solid #dadada;
  width: 100%;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--navy, #2f4454);
  font-family: 'Pretendard';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  /* background: var(--dark-gray-dark-gray-100, #f5f5f5); */
}
.diseases-tag__category-item:first-child {
  border-radius: 8px 8px 0 0;
}
.diseases-tag__category-item:last-child {
  border-radius: 0 0 8px 8px;
  border-bottom: unset;
}

.diseases-tag__category-item--active {
  background: var(--dark-gray-dark-gray-100, #f5f5f5);
  border: unset;
}
/* diseases tag area */

.yarl__container {
  background: rgba(0, 0, 0, 0.5) !important;
}
svg.yarl__icon {
  width: 66px;
  height: 66px;
}
.yarl__toolbar svg.yarl__icon {
  width: 42px;
  height: 42px;
}

.nav-tab-custom {
  margin-top: 4px !important;
}
.nav-tab-custom li:first-child {
  margin-left: 33px !important;
}
.nav-tab-custom li button {
  padding: 0 0 12px !important;
  margin-right: 48px;
  border: none !important;
  color: var(--Gray-scale-Gray-04, #9198a1) !important;
  font-family: Spoqa Han Sans Neo !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 20px; /* 125% */
  letter-spacing: -0.096px;
}
.nav-tab-custom li button.active {
  color: #3593fb !important;
  border-bottom: 2px solid #3593fb !important;
  font-family: Spoqa Han Sans Neo;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  letter-spacing: -0.096px;
}
.tox-tinymce {
  border: none !important;
  border-radius: 0 !important;
}
.tox:not(.tox-tinymce-inline) .tox-editor-header {
  border-bottom: 1px solid #e3e5e8 !important;
  box-shadow: none !important;
}
.tox-statusbar {
  display: none !important;
}
.btn-medical-reply {
  width: 144px;
  height: 52px;
  border-radius: 8px !important;
  border: 1px solid var(--Gray-scale-Gray-01, #e3e5e8) !important;
  background: var(--Default-White, #fff) !important;

  color: var(--Gray-scale-Gray-07, #464c52) !important;
  font-family: Spoqa Han Sans Neo !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 20px !important; /* 125% */
  letter-spacing: -0.096px !important;
}
/* Sidebar */
#sidebar-menu {
  display: flex;
  flex-direction: column;
  background-color: #2f3337;
  width: 17.6rem;
  /* width: calc(100vw - 84%); */
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.4);
  padding-top: 0;
}

.sidebar-menu .nav-link {
  position: relative;
  padding: 0;
}

.sidebar-menu-item {
  display: flex;
  align-items: center;
  color: #9f9fac;
  font-weight: 500;
  padding: 0 3.2rem;
  width: 100%;
  height: 6.8rem;
  color: var(--Gray-scale-Gray-03, #acb2b9);
  /* Title_spoqa/Title 3 */
  font-family: Spoqa Han Sans Neo;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  letter-spacing: -0.096px;
}

.sidebar-menu-item:hover {
  /* background-color: #3591fbb5; */
  color: var(--Default-White, #fff);
  background-color: transparent;
}

.nav-link.active .sidebar-menu-item {
  color: white;
  font-weight: 700;
  background: #3593fb;
  color: var(--Default-White, #fff);
}

.sidebar-menu-item.active::after {
  content: '';
  background-color: var(--main);
  width: 0.4rem;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 100%;
  height: 100% !important;
}

.top-btn {
  width: 144px;
  height: 52px;
  border-radius: 8px !important;
  background: var(--Blue-scale-Blue-07, #3593fb);
  color: var(--Default-White, #fff) !important;
  text-align: center;
  /* Title_spoqa/Title 3 */
  font-family: Spoqa Han Sans Neo !important;
  font-size: 16px !important;
  font-style: normal !important;
  line-height: 20px !important; /* 125% */
  letter-spacing: -0.096px;
}
.top-counter {
  color: var(--Gray-scale-Gray-05, #767f8a);
  font-family: Spoqa Han Sans Neo;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  letter-spacing: -0.084px;
}

.btn-save-post-expert {
  width: 144px;
  height: 52px;
  border-radius: 8px !important;
  border: none !important;
  font-family: 'Spoqa Han Sans Neo' !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 20px !important; /* 125% */
  letter-spacing: -0.096px;
}

.btn-save-post-expert.btn-primary {
  background-color: #3593fb !important;
}
.btn-save-post-expert.btn-secondary {
  background-color: #fff !important;
  border: 1px solid #e3e5e8 !important;
  color: #464c52 !important;
}

.superdat-product-form .modal-dialog .modal-content .modal-body .body-height {
  max-height: calc(100vh - 24px - 71px) !important;
  height: fit-content !important;
}
